$(document).ready(function () {
  $(document).on('keyup change', '.acceptance-checkbox', function () {
    const $this = $(this);
    const hiddenFieldId = $this.data('hidden-field-id');

    const value = $this.prop('checked')
      ? moment().format('YYYY-MM-DD HH:mm:ss')
      : null;

    $('#' + hiddenFieldId).val(value);
  })
});