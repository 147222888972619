$(document).on('turbolinks:load', function () {
    var $fieldsets = $('fieldset.fieldset-category, fieldset.fieldset-entreprise-description');
    var $textareas = $fieldsets.find('textarea');

    $textareas.each(function () {
        var $this = $(this);

        console.log('Alex');

        if (!$this.siblings('.text-count-wrapper').length) {
            $this.textcounter({
                counterText: "Nombre de caractères: %d",
                countSpaces: true,
                countDown: true,
                countDownText: "Nombre de caractères restant: %d",
                displayErrorText: false,
                max: 1500,
                stopInputAtMaximum: false,
                type: "character",
            });
        }
    });

    $(document).off('click', '.js-modal-submit-form');
    $(document).on('click', '.js-modal-submit-form', function (event) {
        event.preventDefault();

        $('#js-project-form').submit();
    });
});
