import $ from "jquery";

$(document).on('turbolinks:before-cache', function () {
    $('div.toast').remove();
});

$( document ).on('turbolinks:load', function() {
    $(document).off('click', '.toast');
    $(document).on('click', '.toast', function () {
        $(this).remove();
    });
});